<template>
	<div class="translate-patword-tools full-width">
		<div class="caption-translate-header">
			<v-card>
				<v-card-text>
					<div class="d-flex justify-space-between">
						<div class="operation-left">
							<v-btn class="el-button">
								<span class="live-stream-time">
									直播流时间 {{global.utils.TimeFormat(liveTime)}}
								</span>
							</v-btn>
						</div>

						<div class="operation-right">
							<v-btn class="el-button" @click="stopPatword">结束任务</v-btn>
							<v-btn class="el-button" @click="overPatword">输出任务报告</v-btn>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</div>

		<div class="caption-translation-content table-margin">
			<div class="row-sya">
				<div :class="`cell ${global.innerWidth > 1900 ? 'large' : ''}`">
					<div class="player-area-wrap">
						<div class="player-area">
							<live-player :qpId="qipuId" type="ts" @playerInited="playerInited" ref="video"></live-player>
							<div class="subtitle-content">
								{{currentContent}}
							</div>
						</div>
					</div>
				</div>

				<div class="celr">
					<div class="video-sets">
						<el-tabs type="card" v-model="tabsValue">
							<el-tab-pane label="拍词" name="patwords">
								<patwords :totalTime="3600000000" v-model="captions" :roleType="4" :player="videoPlayer" :enablePatWord="true" v-if="tabsValue === 'patwords'" />
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>

			<div class="edit-fine row-syb">
				<subtitle-list ref="subtitleList" v-model="captions" :player="videoPlayer" 
				@subtitleChange="subtitleChange"
				@subtitleFocus="subtitleFocus"
				@subtitlePlayClick="subtitlePlayClick"></subtitle-list>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import LivePlayer from "@/components/common/LivePlayer/LivePlayer.vue"
	import Patwords from "@/components/common/Patwords.vue"
	import SubtitleList from "./SubtitleList.vue"
	import axios from "axios"

	let subtitle$$ = axios.create({})

	export default{
		name: "third-pard-patword-patword",
		components: {
			LivePlayer,
			Patwords,
			SubtitleList
		},
		data(){
			return {
				tabsValue: "patwords",
				videoPlayer : {},
				captions: [],
				liveTime : "播放暂未开始",
				taskId: "",
				currentContent: "",
				qipuId: 0
			}
		},
		methods: {
			//播放器初始化事件回调
			playerInited(player){
				this.videoPlayer = player
				this.videoPlayer.PTW.on("timeupdate" , this.updateLiveTime);
			},
			subtitleFocus(row){
				this.selectedCaption = _.cloneDeep(row);
			},
			subtitlePlayClick(){
				console.log(this.selectedCaption.start / 1000)
				this.videoPlayer.seekTo(this.selectedCaption.start / 1000)
			},
			subtitleChange(caption){
				this.captions = caption.map(v => {
					v.translateDialogueContent = v.originalDialogueContent;
					return v;
				});
			},
			//获取任务详情
			async getDetail(){
				let localData = localStorage.getItem("patword-data");
				let serviceData = await $$.get(`/api-intl-translate/subtitle-rhythm-task/query-latest-task-detail`).then(data=>data).catch(err=>this.$message.error(err.message));
				
				if(!serviceData){
					serviceData = {};
				}
				if(!localData){
					localData = {};
				}else{
					try{
						localData = JSON.parse(localData);
					}catch(e){
						localData = {};
					}
				}
				if(!serviceData.taskId){
					this.$message.warning("获取任务数据失败");
					return false;
				}else{
					if(serviceData.taskId == localData.taskId){
						this.handleData(localData);
					}else{
						this.handleData(serviceData);
					}
				}				
			},
			//处理当前数据
			handleData(data){
				this.qipuId = data.qipuId;
				this.captions = data.subtitleDetailList.map(v=>{
					v.translateDialogueContent = v.originalDialogueContent;
					return v;
				});
				this.taskId = data.taskId;
				localStorage.setItem("patword-data" , JSON.stringify(data));
			},
			//结束拍轴
			stopPatword(){
				this.$confirm("确定结束当前拍轴？" , "提示").then(() => {
					let url = `/api-intl-translate/subtitle-rhythm-task/stop`;
					$$.get(url , {
						params: {
							taskId: this.taskId
						}
					}).then(() => {
						this.$message.success("中止成功");
						this.$router.push({name : 'welcome'});
					}).catch(err => {
						this.$message.error(err.message)
					})
				}).catch(() => {})
			},
			//输出任务结果
			overPatword(){
				this.$confirm("确定中止当前拍轴并输出任务结果？" , "提示").then(() => {
					let url = `/api-intl-translate/subtitle-rhythm-task/export-subtitle`;
					subtitle$$.post(url , {
						subtitleDetailList: this.captions,
						taskId: this.taskId
					}).then(data => {
						let header = data.headers;
						let filename = header['content-disposition'].split(";")[1].replace("filename=" , "");
						let downloadUrl = `data:text/plain,${encodeURIComponent(data.data)}`
							utils.contentDownload(downloadUrl , decodeURIComponent(filename));
						localStorage.removeItem("patword-data");
						this.$router.push({name : 'welcome'});
					}).catch(err => {
						this.$message.error(err.response.data.message)
					})
				}).catch(() => {})
			},
			//更新直播时间
			updateLiveTime(){
				this.liveTime = this.videoPlayer.currentTime() * 1000;
				let current = this.captions.filter(v => v.start <= this.liveTime && this.liveTime <= v.end);
				if(current.length > 0){
					this.currentContent = current[0].originalDialogueContent;
				}else{
					this.currentContent = "";
				}
				
			}
		},
		mounted(){
			this.getDetail();
		},
		watch: {
			captions(){
				if(this.captions){
					localStorage.setItem("patword-data" , JSON.stringify({
						qipuId: this.qipuId,
						taskId: this.taskId,
						subtitleDetailList: this.captions
					}))
				}
			}
		},
		beforeDestroy(){
			if(this.videoPlayer){
				this.videoPlayer.PTW.off("timeupdate" , this.updateLiveTime);
			}
		}
	};
</script>

<style lang="scss">
	.translate-patword-tools{
		.live-stream-time{
			display:inline-block;
			margin: 0 50px;
		}

		.caption-translation-content{
			position: relative;
			padding-left:40px;
		}

		.row-sya{
			display: flex;
			justify-content: flex-start;
			margin-bottom: 16px;
			.cell{
				width:500px;
				&.large{
					width : 800px;
				}
				flex: 0 0 auto;
			}
			.celr{
				flex: 1 1 auto;
				margin-left:16px;
			}
		}

		.player-area-wrap{
			position: relative;
			div{
				box-sizing: content-box;
			}
			.edit-mask{
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				z-index: 1000;
				cursor : crosshair;
				background-color: #fff;
				opacity: 55%;
			}
			.subtitle-content{
				position: absolute;
				width: 100%;
				bottom: 5%;
				text-align:center;
				color: white;
			}
		}

		.edit-fine{
			font-size: 14px;
			.table-wrap{
				.table{
					th,
					td{
						text-align: center
					}
				}
				.table-head{
					text-align: center!important;
				}
				.table-body{
					width: 100%;
					max-height: 550px;
				}
				.table-head,
				.table-body{
					overflow-y: scroll;
					&::-webkit-scrollbar{
						width: 8px
					}
					&::-webkit-scrollbar-thumb {
						height: 56px;
						background: hsla(0, 0%, 53.3%, 0.2);
						border-radius: 4px;
					}
					&::-webkit-scrollbar-track {
						background-color: transparent;
					}

					&:hover::-webkit-scrollbar-thumb {
						background-color: #666!important
					}
				}
			}
		}
	}
</style>