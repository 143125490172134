<template>
	<div class="third-pard-patword-setting full-width table-margin">
		<el-form label-width="120px" :rules="rules" :model="settingData" ref="setting">
			<el-form-item label="节目奇谱ID" prop="qipuId">
				<el-input class="middle-item" v-model="settingData.qipuId"></el-input>
			</el-form-item>

			<el-form-item label="字幕导入" prop="subtitle">
				<upload v-model="settingData.subtitle" ref="subtitle" accept="ass,srt"></upload>
			</el-form-item>
		</el-form>

		<div style="margin-left: 120px">
			<el-button type="primary" @click="commit">确定</el-button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Upload from "@/components/common/Upload.vue"
	export default{
		name: "third-pard-patword-setting",
		components: {
			Upload
		},
		data(){
			return {
				settingData: {},
				rules: {
					qipuId: [
						{
							required: true,
							message: "请填写节目奇谱ID"
						}
					],
					subtitle: [
						{
							required: true,
							message: "请选择导入的字幕"
						}
					]
				}
			}
		},
		methods: {
			commit(){
				this.$refs.setting.validate(valid => {
					if(valid){
						let url = `/api-intl-translate/subtitle-rhythm-task/add`;
						$$.post(url , {
							importSubtitleName: this.settingData.subtitle.name,
							importSubtitleUrl: this.settingData.subtitle.url,
							qipuId: this.settingData.qipuId
						}).then(() => {
							this.$message.success("设置成功");
							this.$refs.setting.resetFields();
						}).catch(err => {
							this.$message.error(err.message);
						})
					}
				})
			}
		}
	};
</script>