<template>
	<div class="subtitle-list">
		<!-- table-wrap -->
		<div class="table-wrap">
			<div class="table-head">
				<table class="table">
				<colgroup>
					<col width="30" />
					<col width="110" />
					<col width="110" />
					<col width="160" />
					<col width="60" />
				</colgroup>
				<thead>
					<tr class="table-row">
						<th></th>
						<th>开始时间</th>
						<th>结束时间</th>
						<th>原文</th>
						<th>操作</th>
					</tr>
				</thead>
				</table>
			</div>
			<div class="table-body" v-infinite-scroll="loadMore" :infinite-scroll-distance="10">
				<table class="table">
				<colgroup>
					<col width="30" />
					<col width="110" />
					<col width="110" />
					<col width="160" />
					<col width="60" />
				</colgroup>
				<tbody>
					<tr :class="`table-row`" v-for="(item , index) in captionShowList" :key="item.index" @click="subtitleFocus(item , index)">
						<!-- 字幕序号 -->
						<td>{{item.index + 1}}</td>

						<!-- 字幕开始时间 -->
						<td>
							<div :class="['input-datetime-group', item.patwordsEdit==='start' && 'pattip']">
								<date-time-input v-model="item.start"></date-time-input>
							</div>
						</td>

						<!-- 字幕结束时间 -->
						<td>
							<div :class="['input-datetime-group', item.patwordsEdit==='end' && 'pattip']">
								<date-time-input v-model="item.end" ></date-time-input>
							</div>
						</td>

						<!-- 字幕原文 -->
						<td @dblclick="subtitlePlayClick(item)">
							<input-edit v-model="item.originalDialogueContent"></input-edit>
						</td>

						<!-- 操作 -->
						<td>
							<div :class="`clk-group`">
								<el-dropdown trigger="click" class="clk">
									<span>
										<i title="向下新增一列" class="fa fa-plus-circle" aria-hidden="true"></i>
									</span>

									<el-dropdown-menu slot="dropdown">
								        <el-dropdown-item icon="el-icon-top" @click.native="add(item , 0)">
								        	<span>插入（之前）</span>
								        </el-dropdown-item>
								        <el-dropdown-item icon="el-icon-bottom" @click.native="add(item , 1)">
								        	<span>插入（之后）</span>
								        </el-dropdown-item>
								    </el-dropdown-menu>
								</el-dropdown>
								
								<span v-if="captionList.length > 1" class="clk">
									<i title="删除" class="fa fa-times" aria-hidden="true" @click="remove(item)"></i>
								</span>
							</div>
						</td>
					</tr>
				</tbody>
				</table>
			</div>
		</div>
		<!-- /table-wrap -->
		<div class="pagenav">
			<div class="pagenav-content">{{captionList.length}}条记录</div>
			<div class="min-search">
				<input class="input" type="text" v-model="searchKey" @keyup.enter="search"/>
				<a href="#" class="search-btn" @click.prevent="search"><i class="fa fa-search" aria-hidden="true"></i></a>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		name : "subtitle-list",
		model : {
			prop : "caption",
			event : "subtitleChange"
		},
		props : {
			//字幕列表
			caption : {
				type : Array,
				default : () => {
					return [];
				}
			},
			//页面类型
			type : {
				type : [Number , String],
				default : ""
			},
			//暴露的播放器对象
			player : {
				type : Object,
				default : ()=>({})
			}
		},
		data (){
			return {
				searchKey : "",
				captionList : _.cloneDeep(this.caption),
				captionOri : _.cloneDeep(this.caption),
				showLimit : 30,
				showAdd : 30,
				patWordStatus : false
			}
		},
		computed : {
			captionShowList(){
				if(this.patWordStatus){
					let index = _.findIndex(this.captionList , o => o.left);
					//如果当前拍词时间轴无字幕，就找当前播放时间之后的一条字幕
					if(index == -1){
						index = _.findIndex(this.captionList , o => o.start > this.player.currentTime() * 1000)
					}
					index = _.max([0 , index]);

					let last = _.findLastIndex(this.captionList , o => o.left);
					last = _.max([last + 1 , index + 5]); 
					
					return this.captionList.slice(index , last);
				}else{
					return this.captionList.slice(0 , this.showLimit);
				}
			}
		},
		watch : {
			caption : {
				handler(n , o){
					if(JSON.stringify(n) !== JSON.stringify(o)){
						let captionList = this.caption.map((v , k) => {
							let caption = _.cloneDeep(v);
							caption.index = k;
							return caption;
						});
						if(this.captionList.length == n.length || o.length == 0 || true){
							this.captionList = captionList;
						}
						
						this.captionOri = _.cloneDeep(captionList);
					}
				},
				deep: true
			},
			captionOri : {
				handler(){
					this.$emit("subtitleChange" , this.captionOri);
					this.captionOri.forEach(v => {
						if(v.select)this.$emit("subtitleFocus", v);
					})
				},
				deep : true
			},
			captionList : {
				handler(n , o){
					if(n.length == o.length){
						//优先打平成一个object，否则在map中进行findIndex操作会导致内存溢出卡住
						let listObj = _.fromPairs(this.captionList.map(v => [v.index , v]));
						this.captionOri = this.captionOri.map(v => {
							if(listObj[v.index])return _.cloneDeep(listObj[v.index]);
							else return _.cloneDeep(v);
						})
					}
				},
				deep : true
			}
		},
		methods : {
			patWord(status){
				this.patWordStatus = status;
			},
			add (row , type = 0){
				let index = _.findIndex(this.captionList , o => o.id == row.id);
				let start = row.end;
				let end = row.end;
				if(type == 0){
					//若上一句结束时间高于本句开始时间，则新增的开始时间为本句的开始时间
					end = row.start;
					start = index === 0 ? row.start : this.captionOri[index - 1].end;
					if(start > end){
						start = row.start
					}

				}else if(type == 1){
					//若下一句开始时间低于本句结束时间，则下一句开始时间为新增的结束时间
					start = row.end;
					end = index + 1 == this.captionOri.length ? row.end : this.captionOri[index + 1].start;
					if(start > end){
						end = row.end;
					}
				}
				console.log(start , end);

				let item = {
					start : start,
					end : end
				}

				let rowIndex = _.findIndex(this.captionOri , o => o.id == row.id);
				if(type == 0){
					rowIndex -- ;
					if(rowIndex < 0)rowIndex = 0;
				}else if(type == 1){
					rowIndex ++ ;
				}
				let captionCopy = _.cloneDeep(this.caption);
				captionCopy.splice(rowIndex , 0 , item);
				this.$emit("subtitleChange" , captionCopy);
			},
			remove(row){
				this.$confirm("即将删除本句字幕，请确认？" , "提示").then(()=>{
					let captionCopy = _.cloneDeep(this.caption);
					captionCopy.splice(row.index , 1);
					this.$emit("subtitleChange" , captionCopy);
				}).catch(e => {
					console.log(e);
				})
			},
			subtitleFocus(row , index){
				this.captionList = this.captionList.map(v => {
					v.select = false;
					return v;
				})
				row.select = true;
				this.captionList.splice(index , 1 , row);
				this.$emit("subtitleFocus" , row);
			},
			subtitlePlayClick(row){
				this.$emit("subtitlePlayClick" , row);
			},
			search(){
				this.captionList = this.captionOri.filter(v => ((v.originalDialogueContent || v.originalDialogueContent == '')&& v.originalDialogueContent.indexOf(this.searchKey) !== -1) || ((v.translateDialogueContent || v. translateDialogueContent=='')&& v.translateDialogueContent.indexOf(this.searchKey) !== -1));
			},
			loadMore(){
				let showLimit = this.showLimit + this.showAdd > this.captionList.length ? this.captionList.length : this.showLimit + this.showAdd;
				this.showLimit = this.showLimit > showLimit ? this.showLimit : showLimit;
			}
		},
		created(){
			this.bus.$on("patwords", this.patWord)
		},
		destroyed(){
			this.bus.$off("patwords", {})
		}
	};
</script>

<style lang="scss" scoped="">
	/**表格模块**/
	.table-wrap{
		text-align: left;
		line-height: 18px;
		.table-head{
			text-align: left;
			th{
				padding: 10px 12px;
				background: #fafafa;
				border: 1px solid #e8e8e8;
				border-bottom:0;
			}
		}
		table{
			width:100%;
			border-collapse:collapse;
			border-spacing:0;
			table-layout: fixed;
			td{
				padding: 10px 12px;
				word-break: break-word;
				overflow-wrap: break-word;
				border: 1px solid #e8e8e8;
			}
			tr{
				background-color: #fff;
				transition: all 0.3s;
				&:hover , &.active{
					background-color: #e6f7ff;
				}
				&success{
					background-color: #yellow;
				}
				&error{
					background-color: #red;
				}
			}
		}
	}

	.error-line{
		margin : 0 5px;
		&.comment{
			min-width : 20px;
		}
	}

	.clk-group{
		width:60px;
		margin:0 auto;
		display: flex;
		justify-content: space-between;
		.clk{
			flex: 0 0 auto;
			cursor: pointer;
			padding:5px;
		}
		&.quality{
			width : 100px;
		}
	}

	.pattip {
		position: relative;
		display: inline-block;
		vertical-align: top;
		&:after{
			position: absolute;
			width:10px;
			height:6px;
			left:0;
			bottom:-2px;
			content: '.';
			background:#19ce40;
			animation:pattipAnima 2s linear infinite;
			text-indent: -999px;
			z-index: 2;
			overflow: hidden;
		}
		&:before{
			position: absolute;
			width:100%;
			height: 2px;
			left:0;
			bottom:0;
			content: '.';
			background:#00be06;
			text-indent: -999px;
			z-index: 1;
			overflow: hidden;
		}
	}

	@keyframes pattipAnima {
		0% {
			left:0;
		}
		50%{
			left:90%;
		}
		to {
			left:0;
		}
	}

	.pagenav{
		font-size:14px;
		position: relative;
		text-align: center;
		line-height: 30px;
		padding:10px 0;
		.min-search{
			position: absolute;
			top:10px;
			right:150px;
			.input{
				width: 200px;
				height:30px;
				line-height: 30px;
				padding:6px 30px 6px 6px;
				border:1px solid #DCDFE6;
				border-radius: 4px;
				&:hover{
					border-color: #C0C4CC;
				}
				&:focus{
					border-color: #3f51b5
				}
			}
			.search-btn{
				position: absolute;
				top:0;
				right:0;
				padding:0 7px;
			}
		}
	}

	.subtitle-list {
		.fa-question {
			color : #76CD3C;
		}
		.fa-times {
			color : #D22A10;
		}
		.fa-check {
			color : #BA9757;
		}
		.subtitle-detail-number{
			display : inline-block;
			width : 85px;
			margin : 0 5px;
		}
	}

	.quality-status{
		text-align : center;
		.check-out{
			color : red;
		}
		.check-in{
			color : green;
		}
		.question{
			color : blue;
		}
	}
</style>