<template>
	<div style="display : flex">
			<el-upload
				v-model="img"
				with-credentials
				:action="action"
				:limit="2"
				:file-list="fileList"
				:data="{id : id}"
				:on-success="getSubtitle"
				:on-error="fileError"
				ref="subtitleUpload"
				:accept="accept"
				:before-upload="beforeAvatarUpload"
				:on-remove="removeUpload"
				:show-file-list="false"
				:disabled="disabled"
				>
					<el-button v-if="!disabled" size="small" type="primary">点击上传</el-button>
				</el-upload>
			<div>
				<el-tag style="margin-left : 15px;" 
				:closable="!disabled" 
				v-for="(item , index) in fileList"
				:key="index"
				@close="remove(index)">
					{{item.name}}
				</el-tag>
			</div>
			
		</div>
	
</template>

<script type="text/javascript">
	export default {
		name : "subtitle-uploader",
		props : {
			id : {
				type : [Number , String],
				default : 0
			},
			value : {
				type : Object,
				default : ()=>{
					return {}
				}
			},
			accept : {
				type : String,
				default : ".srt"
			},
			disabled : {
				type : Boolean,
				default : false
			}
		},
		data (){
			if(this.value.url){
				var file_name=this.value.url.replace(/(.*\/)*([^.]+).*/ig,"$2");
				var file_ext=this.value.url.replace(/.+\./,"");
			}
			
			return {
				img : this.value.url,
				picName : this.value.name,
				action : `${this.http_pre}/api-intl-translate/translate-task/upload-subtitle`,
				fileList : this.value.url ? [{
					name : this.value.name ? this.value.name : file_name + "." + file_ext,
					url : this.value.url
				}] : []
			}
		},
		watch : {
			img (){
				this.$emit("input" , {
					url : this.img,
					name : this.picName
				});
			},
			value(n , o){
				if(JSON.stringify(n) !== JSON.stringify(o)){
					if(this.value.url){
						var file_name=this.value.url.replace(/(.*\/)*([^.]+).*/ig,"$2");
						var file_ext=this.value.url.replace(/.+\./,"");
					}
					this.img = this.value.url;
					this.picName = this.value.name;
					this.fileList = this.value.url ? [{
						name : this.value.name ? this.value.name : file_name + "." + file_ext,
						url : this.value.url
					}] : []
				}
			}
		},
		methods : {
			beforeAvatarUpload (file){
				if(file.size >= 10485760){
					this.$message.warning("上传文件超过大小限制，最大10M");
					return false;
				}

				return true;
			},
			fileError(err, file, fileList){
				var message = JSON.parse(err.message)
				this.$message.warning(message.message)
			},
			getSubtitle(response, file, fileList){
				console.log(file);
				if(fileList.length > 1){
					fileList.splice(0 , 1);
				}
				this.fileList = fileList;
				this.img = file.response.data;
				this.picName = file.name;
			},
			removeUpload(file , fileList){
				this.fileList = fileList;
				this.img = "";
				this.picName = "";
			},
			remove(index){
				this.fileList.splice(index , 1);
				this.img = "";
				this.picName = "";
			}
		}
	};
</script>