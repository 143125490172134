<template>
  <div class="thirdpard-time-transfer-tools full-width">
    <div class="content">
      <el-form :model="floatData" :rules="rules" label-width="100px" ref="float">
        <el-form-item label="时间点" prop="time">
          <el-input type="textarea" :rows="8" :placeholder="'时间格式 hh:mm:ss.xxx\n每行请填写一个时间，同批时间同时转换\n示例：\n01:31:41.140\n01:32:46.140\n01:33:17.140'" class="middle-item" v-model="floatData.time"></el-input>
        </el-form-item>

        <el-form-item label="翻译任务ID" prop="taskId">
          <el-input class="middle-item" v-model="floatData.taskId"></el-input>
        </el-form-item>

        <el-form-item label="倍速">
          <el-input class="middle-item" disabled v-model="floatData.videoPlaybackSpeed"></el-input>
        </el-form-item>

        <el-form-item label="片头时长">
          <el-input class="middle-item" disabled v-model="floatData.header"></el-input>
        </el-form-item>
      </el-form>

      <div style="width: 350px;text-align: center;">
        <el-button type="primary" @click="timeCompute(1)">
          <span>
            <i class="fa fa-long-arrow-left" style="margin-right : 5px"></i>
            <span>时间转换</span>
          </span>
        </el-button>

        <el-button type="primary" @click="timeCompute(2)">
          <span>
            <span>时间转换</span>
            <i class="fa fa-long-arrow-right" style="margin-left : 5px"></i>
          </span>
        </el-button>
      </div>

      <el-row style="width: 240px; margin-left: 50px; margin-top: 20px;">
        <el-col :span="12">
          <div>转换前时间</div>
          <div v-for="(originTime, index) of originTimeList" :key="originTime + index">{{originTime}}</div>
        </el-col>
        <el-col :span="12">
          <div>转换后时间</div>
          <div v-for="(transferTime, index) of floatData.transferTimeList" :key="transferTime + index">{{transferTime}}</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script type="text/javascript">
import DateUtils from "@/common/utils/DateUtils.js";
export default {
  name: "thirdpard-time-transfer-tools",
  data() {
    return {
      floatData: {},
      rules: {
        time: [
          {
            required: true,
            message: "请填写时间点",
          },
          {
            validator: (rule, value, callback) => {
              try {
                value
                  .split("\n")
                  .map((item) => item.trim())
                  .filter((item) => item !== "")
                  .map(DateUtils.subtitleTimeToTimeStamp);

                callback();
              } catch (err) {
                callback(err);
              }
            },
            trigger: "change",
          },
        ],
        taskId: [
          {
            required: true,
            message: "请填写任务ID",
          },
        ],
      },
    };
  },
  methods: {
    timeCompute(type = 1) {
      this.$refs.float.validate((valid) => {
        if (valid) {
          Promise.all([this.timetransfer(type), this.getTaskInfo()])
            .then(() => {})
            .catch((err) => {
              this.$message.error(err.message);
            });
        }
      });
    },
    timetransfer(type = 1) {
      let timeList = this.floatData.time
        .split("\n")
        .map((item) => item.trim())
        .filter((item) => item !== "")
        .map(DateUtils.subtitleTimeToTimeStamp);
      return new Promise((resolve, reject) => {
        let url = `/api-intl-translate-vod/subtitle-time-transform`;
        $$.post(url, {
          taskId: this.floatData.taskId,
          transformType: type,
          timeList,
        })
          .then((data) => {
            if (timeList.length != data.length) {
              reject(new Error("时间转换失败"));
              return;
            }
            this.$set(
              this.floatData,
              "transferTimeList",
              data.map((item) => utils.TimeFormat(item))
            );
            this.originTimeList = timeList.map((item) =>
              utils.TimeFormat(item)
            );
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTaskInfo() {
      return new Promise((resolve, reject) => {
        let url = `/api-intl-translate-vod/subtitle-time-transform/cost-param`;
        $$.get(url, {
          params: {
            taskId: this.floatData.taskId,
          },
        })
          .then((data) => {
            this.$set(this.floatData, "header", utils.TimeFormat(data.header));
            this.$set(
              this.floatData,
              "videoPlaybackSpeed",
              data.videoPlaybackSpeed
            );
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.thirdpard-time-transfer-tools {
  .content {
    margin-top: 45px;
  }
}
</style>