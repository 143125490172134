

function isPositiveInteger(num) {
    if (num == null || num === '') {
        return false;
    }
    if (isNaN(num - 0)) {
        return false;
    }
    return (num - 0) >= 0 && !(num + "").includes(".");
}

function appendLeft(str, subStr, count) {
    if (str == null) {
        str = subStr;
    }
    while ((str + "").length < count) {
        str = subStr + "" + str;
    }
    return str;
}

function appendRight(str, subStr, count) {
    if (str == null) {
        str = subStr;
    }
    while ((str + "").length < count) {
        str = str + "" + subStr;
    }
    return str;
}
export default {
    subtitleTimeToTimeStamp(subtitleTime) {
        let ts = 0;
        let [time, sss, errorData] = subtitleTime.split(".");
        if (errorData != null) {
            throw new Error("时间格式错误" + subtitleTime);
        }
        if (sss === "") {
            throw new Error("时间格式错误" + subtitleTime);
        }
        if (sss == null) {
            sss = "000";
        }
        if (sss.length > 3) {
            throw new Error("毫秒超过3位" + subtitleTime);
        } else {
            sss = appendRight(sss, "0", 3);
        }
        if (!isPositiveInteger(sss)) {
            throw new Error("时间格式错误" + subtitleTime);
        }
        ts = sss - 0 + ts;
        let times = time.split(":");
        if (times.length > 3) {
            throw new Error("时间格式错误" + subtitleTime);
        }
        let multiple = 1000;
        for (let i = times.length - 1; i >= 0; i--) {
            if (!isPositiveInteger(times[i])) {
                throw new Error("时间格式错误" + subtitleTime);
            }
            if (times[i] > 59) {
                if (times.length != 3 || i != 0) {
                    throw new Error("时间格式错误" + subtitleTime);
                }
            }
            ts = times[i] * multiple + ts;
            multiple = multiple * 60;
        }
        if (isNaN(ts)) {
            throw new Error("时间格式错误" + subtitleTime);
        }
        return ts;
    },
    toSubtitleTimeString(timeStamp) {
        if (timeStamp == null) {
            return null;
        }
        if (!isPositiveInteger(timeStamp)) {
            throw new Error("时间戳格式错误" + timeStamp);
        }
        let ms = timeStamp % 1000;
        timeStamp = (timeStamp - ms) / 1000;
        let s = timeStamp % 60;
        timeStamp = (timeStamp - s) / 60;
        let m = timeStamp % 60;
        let h = (timeStamp - m) / 60;

        ms = appendLeft(ms, "0", 3);
        s = appendLeft(s, "0", 2);
        m = appendLeft(m, "0", 2);
        h = appendLeft(h, "0", 2);
        return `${h}:${m}:${s}.${ms}`;
    }
}