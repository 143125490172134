<template>
  <el-card class="subtitle-time-alignment-tools full-width">
    <el-form size="small" :model="form" label-width="200px" style="width: 50%">
      <el-form-item label="原始字幕">
        <file-upload accept=".srt,.ass" v-model="form.originalSubtitleFile" />
      </el-form-item>
      <el-form-item label="翻译字幕">
        <file-upload accept=".srt,.ass" v-model="form.translateSubtitleFile" />
      </el-form-item>
      <sub class="upload-tip">若对齐原始字幕，仅上传原始字幕即可；若对齐翻译字幕，仅上传翻译字幕即可</sub>
      <el-divider />
      <el-form-item>
        <el-row type="flex" justify="space-between">
          <el-radio-group v-model="form.type" @change="changeType">
            <el-radio :label="1">翻译任务ID对齐</el-radio>
            <el-radio :label="0">手动对齐</el-radio>
          </el-radio-group>
          <el-button type="primary" size="small" @click="submit">输出对齐字幕</el-button>
        </el-row>
      </el-form-item>
      <template v-if="form.type !== undefind">
        <el-form-item label="翻译任务ID" v-if="form.type === 1">
          <el-input-number :controls="false" type="0.05" v-model="form.translateTaskId"  class="w-200" @blur="getCostParams" />
        </el-form-item>
        <el-form-item label="倍速">
          <el-input-number :controls="false" type="0.05" v-model="form.videoPlaybackSpeed" class="w-200" placeholder="范围(0, 10]" :disabled="form.type === 1" />
        </el-form-item>
        <el-form-item label="片头时间点">
          <el-input v-model="form.videoHeader" placeholder="mm:ss" class="w-200" :disabled="form.type === 1"></el-input>
        </el-form-item>
        <el-form-item label="片尾时间点">
          <el-input v-model="form.videoTail" placeholder="mm:ss" class="w-200" :disabled="form.type === 1"></el-input>
        </el-form-item>
        <el-form-item label="原视频时长" v-if="form.type === 1">
          <el-input v-model="form.videoDuration" placeholder="mm:ss" class="w-200" disabled></el-input>
        </el-form-item>
        <el-form-item label="转码后视频时长" v-if="form.type === 1">
          <el-input v-model="form.afterTranscodingDuration" placeholder="mm:ss" class="w-200" disabled></el-input>
        </el-form-item>
      </template>
    </el-form>
  </el-card>
</template>

<script>
import FileUpload from '@/components/common/Upload'

const defaultParams = {
  videoPlaybackSpeed: undefined,
  videoHeader: '',
  videoTail: '',
  videoDuration: '',
  afterTranscodingDuration: '',
}

export default {
  data() {
    return {
      form: {}
    }
  },

  methods: {
    getCostParams() {
      const {translateTaskId} = this.form;
      if (translateTaskId) {
        $$.get(
          '/api-intl-translate-vod/subtitle-time-transform/cost-param',
          {
            params: {taskId: translateTaskId}
          }
        ).then(({
          afterTranscodingDuration,
          header,
          videoTail,
          videoDuration,
          videoPlaybackSpeed
        }) => {
          this.form.videoPlaybackSpeed = videoPlaybackSpeed;
          this.form.videoHeader = utils.TimeFormat(header, 2);
          this.form.videoTail = utils.TimeFormat(videoTail, 2);
          this.form.videoDuration = utils.TimeFormat(videoDuration, 2);
          this.form.afterTranscodingDuration = utils.TimeFormat(afterTranscodingDuration, 2);
        })
      } else {
        this.form = {
          ...this.form,
          ...defaultParams
        }
      }
    },

    submit() {
      const {
        originalSubtitleFile = {}, translateSubtitleFile = {}, translateTaskId, videoHeader, videoTail, videoPlaybackSpeed
      } = this.form;
      const params = {
        originalSubtitleFile: originalSubtitleFile.url,
        translateSubtitleFile: translateSubtitleFile.url,
        fileName: originalSubtitleFile.name || translateSubtitleFile.name,
        translateTaskId,
        videoHeader: videoHeader ? utils.stringToMSec(videoHeader) : null,
        videoTail: videoTail ? utils.stringToMSec(videoTail) : null,
        videoPlaybackSpeed
      }
      const url = utils.addParams('/api-intl-translate-vod/subtitle-time-transform/file', params)
      utils.funDownload(url);
    },

    changeType(val) {
      this.form = {
        ...this.form,
        ...defaultParams,
        translateTaskId: undefined
      }
    }
  },

  components: {
    FileUpload
  }
}
</script>

<style scoped>
  .upload-tip {
    color: #F56C6C;
    margin-left: 200px;
  }
  .w-200 {
    width: 200px;
  }
</style>